import React from "react"
import style from "./header1.module.css"

export default function Header1(props) {
  return (
    <h1 
     className={style.header}
     style={props.style}
    >
      {props.children}
    </h1>
  )
}
