import React from "react"
import { propTypes } from "react-bootstrap/esm/Image"
import style from "./paragraph.module.css"

export default function Paragraph(props) {
  return (
  <p 
    className={style.header} 
    style={props.style}
    onClick={props.onClick}
  >
    {props.children}
    </p>
  )
}